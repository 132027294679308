<template>
    <WelcomeModule />
    <div style="height: 80px; text-align: center;">
        <a-link href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2020023534号-1</a-link>
    </div>
</template>

<script>
import WelcomeModule from './components/WelcomeModule.vue'

export default {
    name: 'App',
    components: {
        WelcomeModule
    }
}
</script>

<style>

</style>